import React, { Suspense, lazy, useContext, useEffect, useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { MainContext } from '@ezbobdev/ui-framework';
import { MaterialBody, MaterialPage } from '@ezbobdev/ui-widgets-ezbob';

const EzxBody = () => {
  const { actions } = useContext(MainContext);

  useEffect(() => {
    actions.externalServices.datadog?.init();
    actions.externalServices.googleAnalytics?.init();
    actions.externalServices.facebookPixel?.init();
    actions.externalServices.postMessages?.init();
    actions.externalServices.webViewMessages?.init();
  }, [actions.externalServices]);

  return (
    <Suspense>
      <MaterialBody>
        <Route exact path="/">
          <Redirect to={actions.settings.getPageRoute('customerWizard').url} />
        </Route>

        {useMemo(() => {
          const { notFound, ...pageRoutesConfig } = actions.settings.getUserConfigProp('pageRoutes');
          const pageRoutes = Object.entries(pageRoutesConfig);
          pageRoutes.push([notFound.name, notFound]);

          return pageRoutes.map(([routeKey, route]) => {
            const { url, component, allowed } = route;

            if (allowed === false) return null;

            if (!url || !component) {
              // TODO: Fix routes and then enable this error
              // console.warn('Incorrect Route detected', routeKey);

              return null;
            }

            const PageInstance = lazy(() => import(`../../pages/${component}/${component}`));

            return (
              <Route
                key={url}
                path={url}
                component={(props) => (
                  <MaterialPage pageName={routeKey}>
                    <PageInstance {...props} name={routeKey} />
                  </MaterialPage>
                )}
              />
            );
          });
        }, [actions.settings])}
      </MaterialBody>
    </Suspense>
  );
};

export default EzxBody;
