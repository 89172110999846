import React from 'react';

import { MaterialButton, MaterialButtonWrapper } from '@ezbobdev/ui-widgets-ezbob';

const ExpressButton = (props) => {
  return (
    <MaterialButtonWrapper>
      <MaterialButton {...props} />
    </MaterialButtonWrapper>
  );
};

export default ExpressButton;
