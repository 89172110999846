import { useContext } from 'react';

import { MainContext } from '@ezbobdev/ui-framework';
import { css, styled } from '@ezbobdev/ui-widgets-ezbob';

import { PageWrapper } from '..';

const Wrapper = styled(PageWrapper)`
  ${({ theme, variant, topImage }) => {
    return css`
      .ScreenWrapper__inner-container {
        padding-top: unset;
        padding-bottom: unset;
        text-align: ${!!topImage ? 'center' : ''};
      }
      .ScreenWrapper__top-bar {
        margin: ${theme.spacing(3)} 0 ${theme.spacing(4)} 0;
      }
      .ScreenWrapper__top-image {
        max-width: 6rem;
        margin: ${theme.spacing(10)} auto ${theme.spacing(5)};
      }
      .ScreenWrapper__custom-header,
      .ScreenWrapper__header {
        margin-bottom: ${theme.spacing(2)};
      }
      .ScreenWrapper__header__subtitle {
        margin-top: unset;
      }
      .ScreenWrapper__header__title {
        margin-bottom: ${theme.spacing(3)};
      }
      .ScreenWrapper__actions {
        margin: ${theme.spacing(16)} auto ${theme.spacing(16)};
        gap: ${theme.spacing(5)};
      }

      ul {
        list-style: disc;
        margin: 0 ${theme.spacing(3)} ${theme.spacing(2)} 0;
        padding: unset;
      }

      li {
        display: list-item;
        padding: 0;

        p {
          line-height: initial;
        }
      }

      ${theme.breakpoints.up('sm')} {
        .ScreenWrapper__inner {
          ${variant !== 'small' ? 'min-height: 87rem;' : null}
          padding: 0 ${theme.spacing(9)} 0;

          .ScreenWrapper__inner-container {
            height: 100%;
            width: 52.1rem;
            margin: 0 auto;
            padding: 0 0;

            .ScreenWrapper__header__subtitle {
              margin-top: unset;
            }
          }
        }
        .ScreenWrapper__top-image {
          margin-bottom: ${theme.spacing(8)};
        }
      }
    `;
  }}
`;

const DiscountPageWrapper = (props) => {
  const { actions } = useContext(MainContext);
  const currentStepName = actions.userState.getCurrentStepName();
  const titleVariant = actions.settings.getUserConfigProp(`custom.${currentStepName}.titleVariant`);
  const subtitleVariant = actions.settings.getUserConfigProp(`custom.${currentStepName}.subtitleVariant`);

  return (
    <Wrapper
      {...props}
      topImage={props.topImage}
      titleVariant={titleVariant ?? 'h1'}
      subtitleVariant={subtitleVariant}
    />
  );
};

export default DiscountPageWrapper;
