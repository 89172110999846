import React, { useContext } from 'react';

import { MainContext, checkIsWebView, t } from '@ezbobdev/ui-framework';

import { ExpressButton, ExpressContinue } from '../';
import { redirectTo } from '../../utils/common';
import { pageActionType, systemEvents } from '../../utils/constants';
import { useSteps } from '../../utils/useSteps';

const PageActions = (props) => {
  const { name, actions, messagePayload } = props;
  const { shouldMoveToStep } = useSteps(props);
  const { actions: ctxActions } = useContext(MainContext);

  const isWebView = checkIsWebView();

  return actions.map(({ type, url, event, direction, isWebviewAction }, idx) => {
    switch (type) {
      case pageActionType.redirect:
        if (!!isWebviewAction !== isWebView) {
          return null;
        }

        return (
          <ExpressButton key={idx} name="redirect" onClick={() => redirectTo(url, false)} fullWidth>
            {t(`${name}.pageActions.${idx}.label`)}
          </ExpressButton>
        );

      case pageActionType.newTab:
        if (!!isWebviewAction !== isWebView) {
          return null;
        }

        return (
          <ExpressButton key={idx} name="newTab" onClick={() => redirectTo(url, true)} fullWidth>
            {t(`${name}.pageActions.${idx}.label`)}
          </ExpressButton>
        );
      case pageActionType.event:
        if (!!isWebviewAction !== isWebView) {
          return null;
        }

        return (
          <ExpressButton
            key={idx}
            name="event"
            onClick={() => ctxActions.events.emit(systemEvents[event], { payload: messagePayload })}
            fullWidth
          >
            {t(`${name}.pageActions.${idx}.label`)}
          </ExpressButton>
        );
      case pageActionType.progress:
        if (!!isWebviewAction !== isWebView) {
          return null;
        }

        return (
          <ExpressContinue shouldMoveToStep={() => shouldMoveToStep(direction)} fullWidth={true}>
            {t(`${name}.pageActions.${idx}.label`)}
          </ExpressContinue>
        );
      default:
        return null;
    }
  });
};

export default PageActions;
