import React from 'react';

import { t } from '@ezbobdev/ui-framework';
import { MaterialTypography, styled } from '@ezbobdev/ui-widgets-ezbob';

const Wrapper = styled.div`
  .bgWrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .contentWrapper {
      max-width: 110rem;
      margin: 0 auto;
      text-align: center;
      padding: 0.8rem;

      .title {
        font-size: 2.4rem;
      }

      .content {
        font-size: 1.6rem;
      }
    }
  }
`;

const ErrorPage = () => {
  return (
    <Wrapper>
      <div className="bgWrapper">
        <div className="contentWrapper">
          <MaterialTypography className="title">{t('pageError.title')}</MaterialTypography>

          <MaterialTypography className="content">{t('pageError.content')}</MaterialTypography>
        </div>
      </div>
    </Wrapper>
  );
};

export default ErrorPage;
