import React, { useEffect, useState } from 'react';

import Axios from 'axios';

import { useLogger } from '@ezbobdev/ui-commons';
import { MainContext, UIFramework, UI_FW_MODES } from '@ezbobdev/ui-framework';
import { Tools } from '@ezbobdev/ui-sdk';
import { ErrorBoundary, Flow, LicenseInfo, MaterialAppBar, MaterialTheme } from '@ezbobdev/ui-widgets-ezbob';

import config from './config/config';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import { getBaseUrl } from './utils/common';
import { helpType } from './utils/constants';
import { EzxBody, LogoutButton } from './widgets';

Tools.setOption('host', window.API_HOST);
Tools.setOption('protocol', window.API_PROTOCOL || window.location.protocol.replace(':', ''));

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_KEY);

function App() {
  const logger = useLogger();
  const [isSystemLoading, setIsSystemLoading] = useState(true);

  useEffect(() => {
    const isProd = process.env.NODE_ENV === 'production';
    const baseUrl = getBaseUrl();

    logger.init(isProd ? 'warn' : 'debug', { url: `${baseUrl}/logger` });
  }, [logger]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const baseUrl = getBaseUrl();
        const tokenResults = await Axios.get(`${baseUrl}/getAppToken`);

        Tools.setOption('appToken', tokenResults.data.access_token);
        Tools.setOption('tokenType', tokenResults.data.token_type);
      } catch (e) {
        logger.error(e);
      } finally {
        setIsSystemLoading(false);
      }
    };

    getToken();
  }, [logger]);

  // TODO: Set REACT_APP_MUIX_KEY to all devs and then uncomment the check
  // if (!process.env.REACT_APP_MUIX_KEY) return;

  return (
    <ErrorBoundary fallback={<ErrorPage />} didCatch={logger.error}>
      <UIFramework config={config} isSystemLoading={isSystemLoading} mode={UI_FW_MODES.express}>
        <MainContext.Consumer>
          {({ actions }) => {
            const { getUserConfigProp } = actions.settings;

            const { logo } = getUserConfigProp('assets');
            const theme = getUserConfigProp('materialTheme');
            const isRouteMounted = !!actions.view.getView('routeSettings.name');

            return (
              <MaterialTheme theme={theme}>
                <Flow>
                  {isRouteMounted && (
                    <MaterialAppBar
                      name="materialAppBar"
                      muiAppBar={{ elevation: 0 }}
                      logo={<img style={{ maxHeight: logo.height }} src={logo.src} alt={logo.alt} />}
                      logoutButton={<LogoutButton />}
                      helpType={helpType}
                      actions={true}
                      containerMaxWidth={'xl'}
                    />
                  )}

                  <EzxBody />
                </Flow>
              </MaterialTheme>
            );
          }}
        </MainContext.Consumer>
      </UIFramework>
    </ErrorBoundary>
  );
}

export default App;
