import React from 'react';

import ExpressContinue from '../ExpressContinue/ExpressContinue';

const ExpressSecondaryContinue = (props) => {
  return <ExpressContinue variant="outlined" {...props} data-testid="secondary-continue-button" />;
};

ExpressSecondaryContinue.displayName = 'ExpressSecondaryContinue';

export default ExpressSecondaryContinue;
