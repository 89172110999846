export const getBaseUrl = () => {
  const { APP_TOKEN_PROTOCOL, APP_TOKEN_HOST, APP_TOKEN_PORT, location } = window;

  if (!APP_TOKEN_HOST) {
    return '';
  }

  const protocol = APP_TOKEN_PROTOCOL || location.protocol.replace(':', '');
  const port = APP_TOKEN_PORT ? `:${APP_TOKEN_PORT}` : '';
  const baseUrl = `${protocol}://${APP_TOKEN_HOST}${port}`;

  return baseUrl;
};

export const redirectTo = (url, newTab = false) => {
  newTab ? window.open(url) : (window.location.href = url);
};

export const getUrlQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlParam = urlParams.get(param);

  return urlParam;
};

/**
 * validateDateByRules
 *
 * @description Takes a `rulesArr` and interates over the rules to check if the `momentDate` matches the rules
 * @param {moment} momentDate Moment object
 * @param {Object[]} rulesArr Array of objects, where each object has `operator` (moment function name) and `value` (string representing date)
 * @returns Boolean
 */
export const validateDateByRules = (momentDate, rulesArr) => {
  for (let i = 0; i <= rulesArr.length; i += 1) {
    const rule = rulesArr[0];

    if (typeof momentDate[rule.operator] === 'function') {
      const isValid = momentDate[rule.operator](rule.value);

      if (!isValid) return false;
    }
  }

  return true;
};

export const getPfdObjectUrlFromArrayBuffer = (arrayBuffer) => {
  const file = new Blob([arrayBuffer], { type: 'application/pdf' });
  return URL.createObjectURL(file);
};

export const downloadDocument = (arrayBuffer, docType, fileName = 'download') => {
  const blob = new Blob([arrayBuffer], { type: docType });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const isScrolledToBottom = ({ target: { scrollHeight, clientHeight, scrollTop } }) => {
  const threshold = 20;
  return Math.ceil(scrollTop) >= scrollHeight - clientHeight - threshold;
};
