import { useContext } from 'react';

import { MainContext, useTranslation } from '@ezbobdev/ui-framework';
import { css, styled } from '@ezbobdev/ui-widgets-ezbob';

import { PageWrapper } from '../../widgets';

const Wrapper = styled(PageWrapper)`
  ${({ theme, isFinal }) => {
    return css`
      .ScreenWrapper__header,
      .ScreenWrapper__custom-header {
        .ScreenWrapper__header__subtitle {
          margin-top: ${theme.spacing(1)};
        }
      }

      .ScreenWrapper__inner {
        text-align: center;

        .ScreenWrapper__top-bar {
          .MaterialBack {
            text-decoration: none;
          }
        }

        .ScreenWrapper__inner-container {
          padding: ${theme.spacing(2)};
        }

        .MuiInputBase-root {
          .MuiSelect-select {
            text-align: right;
            padding-right: ${theme.spacing(2)};
          }
        }
      }

      .ScreenWrapper__top-image {
        max-width: 8rem;
        margin: 0 auto ${theme.spacing(4)};
      }

      .ScreenWrapper__header {
        margin: 0 auto ${theme.spacing(4)};
      }

      .ScreenWrapper__actions {
        margin: ${theme.spacing(2)} 0;
        align-items: end;
        flex-direction: row;
        gap: ${theme.spacing(1)};

        .MaterialButtonWrapper {
          width: 100%;
        }

        .disclaimer {
          color: ${theme.palette.grey.main};
        }
      }

      .ScreenWrapper__footer {
        display: none;
      }

      ${theme.breakpoints.up('sm')} {
        .ScreenWrapper__inner {
          background-position: left top;
          background-size: auto;
          max-width: 102.8rem;
          min-height: 80rem;
          padding: unset;

          .ScreenWrapper__top-bar {
            padding: 0 ${theme.spacing(5)};
            margin-bottom: unset;
          }
        }

        .ScreenWrapper__header {
          max-width: 53.6rem;
        }

        .ScreenWrapper__inner-container {
          padding: ${theme.spacing(8)} 0 ${theme.spacing(2)};

          .ScreenWrapper__content {
            margin: 0 auto;
            width: 43.8rem;
          }

          .ScreenWrapper__actions {
            margin: ${theme.spacing(2)} auto 0;
            width: 43.8rem;
          }

          &.progressBar {
            padding: 0 0 ${theme.spacing(3)};
            min-height: 200px;
          }

          .ScreenWrapper__footer {
            display: block;
            margin-top: ${theme.spacing(3)};
          }
        }

        .ScreenWrapper__progress-bar {
          border-radius: 0.8rem 0.8rem 0 0;
          padding: 0;

          .MuiStepConnector-root {
            right: calc(-50% + 30px);
            left: calc(50% + 30px);
          }
        }

        .MuiFormControlLabel-label {
          text-align: start;
          line-height: 1;
        }
      }

      ${isFinal &&
      css`
        .ScreenWrapper__inner-container {
          justify-content: center;
          padding-top: ${theme.spacing(2)};

          > :first-child {
            margin-top: auto;
          }

          > .ScreenWrapper__actions {
            margin-top: auto;
            flex-grow: 0;
          }
        }
      `}
    `;
  }}
`;

const MorningPageWrapper = (props) => {
  const { t, i18n } = useTranslation();
  const { actions } = useContext(MainContext);
  const currentStepName = actions.userState.getCurrentStepName();
  const { footerImageSrc } = actions.settings.getUserConfigProp(`custom.${currentStepName}`) ?? {};

  return (
    <Wrapper
      {...props}
      footer={
        footerImageSrc && (
          <img
            src={footerImageSrc}
            alt={i18n.exists(`${currentStepName}.footerAlt`) ? t(`${currentStepName}.footerAlt`) : undefined}
          />
        )
      }
    />
  );
};

export default MorningPageWrapper;
