import React, { useContext } from 'react';

import { MainContext, redirectToUrl, t } from '@ezbobdev/ui-framework';
import {
  MaterialButton,
  MaterialDialog,
  MaterialDialogActions,
  MaterialDialogContent,
  MaterialDialogTitle,
  MaterialTypography,
  styled,
} from '@ezbobdev/ui-widgets-ezbob';

const Wrapper = styled.div``;

const DialogActionsWrapper = styled.div`
  padding: 1.6rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .MaterialButton {
    max-width: 10rem;
    flex-grow: 0;

    &:last-of-type {
      margin-left: 1.6rem;
    }
  }
`;

const CancelPopup = ({ open, onClose }) => {
  const { actions, sdkActions } = useContext(MainContext);

  const handleCancelCase = async () => {
    await sdkActions.case.cancel();
    redirectToUrl(actions.settings.getUserConfigProp('routing.vendorWebsiteUrl'));
  };

  return (
    <Wrapper>
      <MaterialDialog
        open={open}
        label={<MaterialTypography>{t('custom.cancelPopup.popup.title')}</MaterialTypography>}
        onClose={onClose}
      >
        <MaterialDialogTitle onClose={onClose}>
          <MaterialTypography variant="h2">{t('custom.cancelPopup.popup.title')}</MaterialTypography>
        </MaterialDialogTitle>

        <MaterialDialogContent>
          <MaterialTypography>{t('custom.cancelPopup.popup.content')}</MaterialTypography>
        </MaterialDialogContent>

        <MaterialDialogActions>
          <DialogActionsWrapper>
            <MaterialButton variant="outlined" className="deny" fullWidth onClick={onClose}>
              {t('custom.cancelPopup.popup.decline')}
            </MaterialButton>

            <MaterialButton className="confirm" fullWidth onClick={handleCancelCase}>
              {t('custom.cancelPopup.popup.confirm')}
            </MaterialButton>
          </DialogActionsWrapper>
        </MaterialDialogActions>
      </MaterialDialog>
    </Wrapper>
  );
};

export default CancelPopup;
