import React from 'react';

import { MaterialDivider, styled } from '@ezbobdev/ui-widgets-ezbob';

const StyledDivider = styled(MaterialDivider)`
  ${(props) => {
    const { theme } = props;

    return `
            margin: ${theme.spacing(2)} auto;
            width: 80px;
    `;
  }}
`;

const Divider = () => {
  return <StyledDivider />;
};

export default Divider;
