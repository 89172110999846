import React from 'react';

import { MaterialButtonWrapper, MaterialContinue } from '@ezbobdev/ui-widgets-ezbob';

const ExpressContinue = (props) => {
  return (
    <MaterialButtonWrapper>
      <MaterialContinue fullWidth {...props} stateDriven size="large" />
    </MaterialButtonWrapper>
  );
};

ExpressContinue.displayName = 'ExpressContinue';

export default ExpressContinue;
