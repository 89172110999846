const baseColors = {
  none: 'transparent',
  primaryVariant: '#353535',
  primary: '#623fd0',
  primaryLight: '#D3C9F0',
  materialPrimary: '#623fd0',
  secondary: '#C1E9EB',
  materialSecondary: '#FFFFFF',
  secondaryVariant: '#CCD32B',
  buttonSupport: '#D3D3D3',
  box: '#78D5D7',
  error: '#f44336',
  success: '#A2D9A2',
  textNegative: '#FFFFFF',
  textLabel: '#353535',
  primaryLink: '#0B68B8',
  textPositive: '#000000',
  borderSupport: '#D9DDE0',
  reserve01: '#c1c5c7',
  reserve02: '#C0C3C7',
  reserve03: '#dcdcdc',
  neutralAlert: '#FF9429',
  focus: '#3F88C5',
  white: '#ffffff',
  black: '#000000',
  primaryBackground: '#f5f2ff',
  secondaryBackground: '#3819A3',
  darkerBackground: '#cdc4ed',
  frameBackground: '#ffffff',
  bodyBackground: '#fff',
  materialAppBarBackground: '#3819A3',
  materialPrimaryDisabled: '#E0E0E0',
  materialPrimaryLink: '#000000',
  border: '#0000001F',
  brightGray: '#ededed',
};

const theme = {
  // ezx
  fonts: [
    { font: `Montserrat`, weights: [100, '100i', 300, '300i', 400, '400i', 600, '600i', 700, '700i', 800, '800i'] },
  ],
  breakpoints: { xs: 0, sm: 665, md: 960, lg: 1280, xl: 1920 },
  spacing: {
    none: '0',
    xxs: '0.2rem',
    xs: '0.4rem',
    sm: '1rem',
    md: '2.4rem',
    lg: '3.6rem',
    xl: '4rem',
  },
  main: {
    background: 'colors.primaryBackground',
  },
  flow: {
    background: 'colors.primaryBackground',
  },
  screen: {
    phone: '480px',
  },
  favicon: {
    url: `https://code.ezbob.com/app-ezx/resources/images/favicon.png`,
    alertCount: null,
    animated: true,
    animationDelay: 500,
  },
  colors: { ...baseColors },
  body: {
    backgroundColor: 'colors.bodyBackground',
    sm: {
      backgroundColor: 'colors.secondaryBackground',
    },
  },
  screenWrapper: {
    content: {
      color: 'colors.textPositive',
    },
    background: 'colors.none',
    back: {
      color: 'colors.textPositive',
      border: 'colors.textPositive',
    },
    border: {
      color: 'colors.buttonSupport',
    },
    topImage: {
      maxWidth: '140px',
    },
  },
  materialAppBar: {
    backgroundColor: 'colors.materialAppBarBackground',
    color: 'colors.textNegative',
    loginSignupButton: {
      color: 'colors.textNegative',
    },
    height: '60px',
    sm: {
      height: '60px',
      color: 'colors.textNegative',
      backgroundColor: 'colors.materialAppBarBackground',
    },
  },
  materialDialog: {
    title: {
      color: 'colors.materialPrimary',
    },
    content: {
      color: 'colors.textMuted',
    },
  },
  MaterialShareholders: {
    NoDataMessage: {
      background: 'colors.none',
      color: 'colors.primary',
    },
  },
  materialPersonsSignificantControl: {
    listItem: {
      backgroundColor: 'colors.none',
      color: 'colors.primary',
      selected: {
        backgroundColor: 'colors.materialPrimary',
        color: 'colors.materialSecondary',
      },
      hover: {
        backgroundColor: 'colors.materialPrimary',
        color: 'colors.materialSecondary',
      },
    },
    radio: {
      color: 'colors.primary',
      selected: {
        color: 'colors.materialPrimary',
      },
      disabled: {
        color: 'colors.materialPrimaryDisabled',
      },
    },
  },
  materialUploadDocument: {
    icon: {
      color: 'colors.materialPrimary',
    },
    border: {
      color: 'colors.buttonSupport',
    },
    backgroundColor: 'colors.primaryBackground',
  },
  materialLogin: {
    backgroundColor: 'colors.white',
    color: 'colors.materialPrimary',
    title: {
      color: 'colors.materialPrimary',
    },
    closedAppsTitle: {
      color: 'colors.textMuted',
      weight: 400,
    },
    backButton: {
      color: 'colors.primaryVariant',
    },
    card: {
      other: {
        color: 'colors.materialPrimaryDisabled',
      },
      expired: {
        color: 'colors.error',
      },
      color: 'colors.materialPrimary',
      backgroundColor: 'colors.white',
      cancelled: { color: 'colors.primary' },
      completed: { color: 'colors.success' },
      borderClosed: { color: 'colors.materialPrimaryDisabled' },
    },
    summary: {
      backgroundColor: 'colors.primaryBackground',
      button: {
        backgroundColor: 'colors.error',
      },
    },
  },
  materialTypography: {
    h1: {
      fontSize: '3.2rem',
      fontWeight: 400,
      color: 'colors.materialPrimary',
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: 400,
      color: 'colors.materialPrimary',
      sm: {
        fontSize: '3.2rem',
      },
    },
    body1: {
      fontSize: '1.5rem',
      color: 'colors.textPositive',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1.3rem',
      color: 'colors.textPositive',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1.5rem',
      fontWeight: 600,
      sm: {
        fontSize: '1.6rem',
        fontWeight: 600,
      },
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontWeight: 500,
      sm: {
        fontSize: '1.4rem',
        fontWeight: 500,
      },
    },
    caption: {
      fontSize: '1.1rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  },
  materialIdVerification: {
    // TODO: Check with Paz how to declare colors without to change old widgets
    progress: {
      success: '#387f3c',
      error: '#E52828',
    },
    photo: {
      color: 'colors.black',
      background: 'colors.white',
      selected: {
        background: 'colors.white',
        color: '#003B5A',
      },
    },
  },
  materialSelect: {
    label: {
      color: 'colors.primary',
    },
    input: {
      borderColor: 'colors.primary',
    },
    menuItem: {
      selected: {
        backgroundColor: 'colors.materialPrimary|chroma|alpha|0.098',
      },
    },
  },
  materialOptionalOffers: {
    repaymentRate: {
      color: 'colors.darkBlue',
      fontWeight: 500,
    },
  },
  materialProgressBar: {
    label: {
      fontWeight: '600',
    },
    icon: {
      color: 'colors.materialPrimary',
      backgroundColor: 'colors.white',
      width: '3.5rem',
      height: '3.5rem',
    },
    connector: {
      color: 'colors.materialPrimary',
      border: 'colors.buttonSupport',
    },
    colors: {
      primary: '',
      grey: 'colors.buttonSupport',
    },
  },
  materialCheckbox: {
    primary: {
      border: 'colors.textPositive',
      color: 'colors.textPositive',
      checked: {
        color: 'colors.textPositive',
        border: 'colors.success',
      },
      disabled: {
        color: 'colors.materialPrimaryDisabled',
        border: 'colors.materialPrimaryDisabled',
      },
    },
  },
  materialRadio: {
    color: 'colors.primary',
    primary: {
      border: 'colors.textPositive',
      color: 'colors.textPositive',
      checked: {
        color: 'colors.textPositive',
        border: 'colors.materialPrimary',
      },
      disabled: {
        color: 'colors.materialPrimaryDisabled',
        border: 'colors.materialPrimaryDisabled',
      },
    },
  },
  materialButtonWrapper: {
    width: '28rem',
  },
  materialButton: {
    defaultWidth: '27rem',
    contained: {
      primary: {
        color: 'colors.white',
        backgroundColor: 'colors.primary',
        disabled: {
          backgroundColor: 'colors.materialPrimaryDisabled',
          color: 'colors.textPositive',
          border: 'transparent',
        },
      },
    },
    text: {
      primary: {
        backgroundColor: 'transparent',
        color: 'colors.materialPrimaryLink',
        border: 'transparent',
        textDecoration: 'underline',
        disabled: {
          backgroundColor: 'transparent',
          color: 'colors.textPositive',
          border: 'transparent',
        },
      },
    },
    outlined: {
      primary: {
        backgroundColor: 'colors.materialSecondary',
        color: 'colors.materialPrimary',
        border: 'colors.materialPrimary',
        disabled: {
          backgroundColor: 'colors.materialSecondary',
          color: 'colors.textPositive',
          border: 'colors.textPositive',
        },
      },
    },
  },
  materialContinue: {
    contained: {
      primary: {
        backgroundColor: 'colors.materialPrimary',
        color: 'colors.textNegative',
        border: 'transparent',
        disabled: {
          backgroundColor: 'colors.materialPrimaryDisabled',
          color: '#474747',
          border: 'transparent',
        },
      },
    },
    text: {
      primary: {
        backgroundColor: 'transparent',
        color: 'colors.materialPrimaryLink',
        border: 'transparent',
        disabled: {
          backgroundColor: 'transparent',
          color: 'colors.textPositive',
          border: 'transparent',
        },
      },
    },
    outlined: {
      primary: {
        backgroundColor: 'colors.materialSecondary',
        color: 'colors.materialPrimary',
        border: 'colors.materialPrimary',
        disabled: {
          backgroundColor: 'colors.materialSecondary',
          color: 'colors.textPositive',
          border: 'colors.textPositive',
        },
      },
    },
  },
  materialDatePicker: {
    label: {
      color: 'colors.primary',
    },
    input: {
      borderColor: 'colors.primary',
    },
    toolbar: {
      backgroundColor: 'colors.materialPrimary',
    },
    daySelected: {
      color: 'colors.textNegative',
      backgroundColor: 'colors.materialPrimary',
    },
  },
  materialFab: {
    circular: {
      primary: {
        backgroundColor: 'colors.primary',
        color: 'colors.white',
        disabled: {
          backgroundColor: 'colors.primaryLight',
          color: 'colors.white',
        },
      },
    },
  },
  materialTooltip: {
    icon: {
      color: 'colors.primaryVariant',
    },
    backgroundColor: 'colors.primaryVariant',
    color: 'colors.white',
  },
  rlsQuestion: {
    border: {
      color: 'colors.border',
    },
    button: {
      height: 'spacing.lg',
      width: 'spacing.xl',
      active: {
        backgroundColor: 'colors.primary',
        color: 'colors.textNegative',
      },
    },
  },
  panel: {
    background: 'colors.textLabel',
    color: 'colors.textNegative',
    hoverColor: 'colors.secondary',
    paragraph: {
      color: 'colors.readableNegative',
    },
  },
  typography: {
    primary: {
      color: 'colors.primary',
    },
    secondary: {
      color: 'colors.secondary',
    },
    textPrimary: {
      color: 'colors.textPositive',
    },
    textSecondary: {
      color: 'colors.textNegative',
    },
    error: {
      // color: chroma("colors.error").brighten(0.5).hex(),
      color: 'colors.error|chroma|brighten|0.5',
    },
    third: {
      color: 'colors.secondaryVariant',
    },
  },
  paragraph: {
    light: {
      color: 'colors.textNegative',
    },
    dark: {
      color: 'colors.textPositive',
    },
  },
  alerts: {
    success: {
      color: 'colors.textNegative',
      background: 'colors.success',
      border: 'colors.success',
      link: 'colors.textNegative',
    },
    info: {
      color: 'colors.textNegative',
      background: 'colors.focus',
      border: 'colors.focus',
      link: 'colors.textNegative',
    },
    error: {
      color: 'colors.textNegative',
      background: 'colors.error',
      border: 'colors.error',
      link: 'colors.textNegative',
    },
    warning: {
      color: 'colors.textNegative',
      background: 'colors.neutralAlert',
      border: 'colors.neutralAlert',
      link: 'colors.textNegative',
    },
  },
  label: {
    light: {
      color: 'colors.textNegative',
    },
    dark: {
      color: 'colors.textPositive',
    },
  },
  button: {
    default: {
      background: 'colors.secondary',
      color: 'colors.textPositive',
      hoverBackground: 'colors.secondary|chroma|darker|0.7',
    },

    primary: {
      color: 'colors.primary',
      hoverBackground: 'colors.buttonSupport|chroma|darker|0.7',
      border: 'colors.primaryVariant',
      background: 'colors.buttonSupport',
    },
    secondary: {
      background: 'colors.box',
      color: 'colors.primaryVariant',
      hoverBackground: 'colors.secondary|chroma|darker|0.7',
    },
    disabled: {
      background: 'colors.buttonSupport|chroma|brighten|0.5',
      color: 'colors.buttonSupport',
      border: 'colors.buttonSupport|chroma|brighten|0.5',
    },
    actionButton: {
      background: 'colors.secondary',
    },
    outlined: {
      color: 'colors.box',
    },
  },
  Signature: {
    border: 'colors.primaryVariant',
  },
  alertUi: {
    color: 'colors.textNegative',
    background: 'colors.error',
    border: 'colors.textNegative',
    link: 'colors.textNegative',
    success: {
      color: 'colors.background',
      background: 'colors.success',
      border: 'colors.success',
    },
    info: {
      color: 'colors.primary',
      background: 'colors.focus',
      border: 'colors.focus',
    },
    error: {
      color: 'colors.textNegative',
      background: 'colors.error',
      border: 'colors.error',
    },
    warning: {
      color: 'colors.textNegative',
      background: 'colors.neutralAlert',
      border: 'colors.neutralAlert',
    },
  },
  dot: {
    border: 'colors.buttonSupport',
    valid: {
      background: 'colors.success',
      border: 'colors.success',
    },
    invalid: {
      background: 'colors.error',
      border: 'colors.error',
    },
  },
  popupLoader: {
    background: 'colors.textPositive',
    dots: 'colors.secondary',
  },
  toggleButton: {
    color: 'colors.borderSupport',
  },
  tooltip: {
    color: 'colors.secondary',
    // hoverColor: chroma("colors.secondary").darker(0.7).hex(),
    hoverColor: 'colors.secondary|chroma|darken|0.7',
    iconTextColor: 'colors.textNegative',
    content: {
      border: 'colors.reserve02',
      color: 'colors.textPositive',
    },
  },
  confirmationPopup: {
    link: {
      color: 'colors.primaryLink',
    },
  },
  generalError: {
    errorSignBackground: 'colors.primary',
    errorSign: 'colors.secondaryVariant',
  },
  notFound: {
    context: 'colors.primary',
  },
  general: {
    focus: 'colors.focus',
    success: 'colors.success',
    error: 'colors.error',
    warning: 'colors.neutralAlert',
  },
  congratulations: {
    backgroundColor: 'colors.white',
    color: 'colors.textMuted',
    breakDown: {
      color: 'colors.primary',
    },
    feesTitle: {
      color: 'colors.textLabel',
    },
    listFooter: {
      color: 'colors.textLabel',
    },
  },
  vatLinking: {
    loader: {
      color: 'colors.primary',
    },
    success: {
      color: 'colors.primary',
      iconColor: 'colors.success',
    },
  },
  applicationSummary: {
    background: 'colors.brightGray',
  },
  materialTextField: {
    label: {
      color: 'colors.primary',
    },
    input: {
      borderColor: 'colors.primary',
    },
  },
  materialAddressLookup: {
    label: {
      color: 'colors.primary',
    },
    input: {
      borderColor: 'colors.primary',
    },
  },
};
export default theme;
