import React from 'react';

import { MaterialCurrency, MaterialTypography, styled } from '@ezbobdev/ui-widgets-ezbob';

const Wrapper = styled.div`
  .congratulationsPage-title {
    margin-bottom: 2.4rem;
  }

  .congratulationsPage-image {
    display: flex;
    justify-content: center;
    margin-bottom: 2.4rem;

    img {
      max-height: 3.6rem;
      max-width: 4rem;
    }
  }

  .bigNumber {
    font-size: 3.2rem;
    font-weight: 600;
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const OfferHeader = ({ title, subtitle, helperText, image, amountProps }) => (
  <Wrapper>
    {Boolean(image) && <div className="congratulationsPage-image">{image}</div>}

    {title && (
      <MaterialTypography variant="h1" className="congratulationsPage-title" align="center">
        {title}
      </MaterialTypography>
    )}

    {subtitle && (
      <>
        <MaterialTypography className="congratulationsPage-subtitle" align="center">
          {subtitle}
        </MaterialTypography>

        <MaterialTypography variant="h2" component="bod1" color="primary">
          <MaterialCurrency name={amountProps.configPath} className="bigNumber">
            {amountProps.value}
          </MaterialCurrency>
        </MaterialTypography>
        <div className="funding-amount" hidden>
          {amountProps.value}
        </div>
      </>
    )}

    {helperText && (
      <MaterialTypography className="congratulationsPage-helperText" align="center">
        {helperText}
      </MaterialTypography>
    )}
  </Wrapper>
);

export default OfferHeader;
