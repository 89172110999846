import { useContext } from 'react';

import { MainContext } from '@ezbobdev/ui-framework';

import { rumActionType, wizardDirection } from './constants';

export const useSteps = () => {
  const { sdkActions, actions } = useContext(MainContext);
  const currentStep = actions.userState.getCurrentStepName();
  const emailVerificationCfg = actions.settings.getUserConfigProp('system.emailVerification') || {};

  const shouldMoveToStep = async (direction = wizardDirection.forward) => {
    if (emailVerificationCfg.validate && emailVerificationCfg.validateOnStep === currentStep) {
      const isEmailVerified = await sdkActions.users.getEmailVerificationStatus();

      if (!isEmailVerified) {
        actions.view.toggleModal({
          modalName: 'VerifyEmail',
          props: {
            isOpen: true,
            onClose: actions.view.toggleModal,
          },
        });

        return false;
      }
    }

    const progressResults = await sdkActions.case.progress(direction);
    const nextStepDetails = actions.userState.getStepDetails(progressResults.data.step);

    actions.case.updateInCase('meta.ui.isBackwardPresent', progressResults.data.isBackwardPresent);
    actions.case.updateInCase('meta.ui.isForwardSecondaryPresent', progressResults.data.isForwardSecondaryPresent);

    if (nextStepDetails && nextStepDetails.retrieveCaseBeforeLoad) {
      await sdkActions.case.retrieve(false);
    }

    if (actions.externalServices && actions.externalServices.datadog) {
      actions.externalServices.datadog.addAction(rumActionType.progress, {
        origin: currentStep,
        destination: progressResults.data.step,
      });
    }

    return progressResults.data.step;
  };

  return { shouldMoveToStep, currentStep };
};
