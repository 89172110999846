import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { useHeader, withWidget } from '@ezbobdev/ui-commons';
import { MainContext, t } from '@ezbobdev/ui-framework';
import { Tools } from '@ezbobdev/ui-sdk';
import { MaterialButton } from '@ezbobdev/ui-widgets-ezbob';

const LogoutButton = (props) => {
  const { history } = props;

  const { actions } = useContext(MainContext);
  const { logout, login } = useHeader(props, 'MaterialAppBar');

  const authorities = actions.userState.getUserState('authorities');
  const customerWizardUrl = actions.settings.getPageRoute('customerWizard').url;
  const allowSignUp = actions.settings.getUserConfigProp('system.allowSignup');

  // On broker mode don't display any button
  if (authorities.includes('ROLE_BROKER')) return undefined;

  const userData = Tools.getOption('userData');

  if (window.location.pathname === customerWizardUrl) {
    return userData ? (
      <MaterialButton
        variant="text"
        color="inherit"
        className="logout"
        title={userData.username}
        onClick={logout}
        data-testid="logout-button"
      >
        {t(`header.logout`)}
      </MaterialButton>
    ) : (
      <MaterialButton variant="text" color="inherit" className="login" onClick={login} data-testid="login-button">
        {t(`header.login`)}
      </MaterialButton>
    );
  } else {
    if (userData) {
      return (
        <MaterialButton variant="text" color="inherit" className="logout" title={userData.username} onClick={logout}>
          {t(`header.logout`)}
        </MaterialButton>
      );
    } else if (allowSignUp) {
      return (
        <MaterialButton
          className="signup"
          color="inherit"
          variant="text"
          onClick={() => history.push(customerWizardUrl)}
        >
          {t(`header.signup`)}
        </MaterialButton>
      );
    }
  }

  return undefined;
};

LogoutButton.displayName = 'LogoutButton';

LogoutButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(React.memo(withWidget(LogoutButton)));
