import { useContext } from 'react';

import { MainContext, useTranslation } from '@ezbobdev/ui-framework';
import { css, styled } from '@ezbobdev/ui-widgets-ezbob';

import { PageWrapper } from '../../widgets';

const Wrapper = styled(PageWrapper)`
  ${({ theme }) => {
    return css`
      & {
        background-position: right;
        background-repeat: no-repeat;
        min-height: calc(100vh - 6.4rem);
        background-size: contain;

        ${theme.breakpoints.up('sm')} {
          padding: 0;
        }

        ${theme.breakpoints.up('md')} {
          padding: ${theme.spacing(3)} 0;
        }

        ${theme.breakpoints.up('lg')} {
          padding: ${theme.spacing(3)} 0;
        }

        ${theme.breakpoints.up('xl')} {
          padding: ${theme.spacing(5)} 0;
        }
      }

      .ScreenWrapper__inner {
        max-width: 102.4rem;
        padding: 0;
        display: flex;
        align-items: center;
        box-shadow: ${theme.shadows[1]};

        .ScreenWrapper__progress-bar {
          background-color: ${theme.palette.background.default};
          border-bottom: 1px solid ${theme.palette.divider};
          width: 100%;
          height: 7.2rem;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }

          ${theme.breakpoints.up('sm')} {
            border-radius: 2.4rem 2.4rem 0 0;
          }
        }

        .ScreenWrapper__inner-container {
          padding: ${theme.spacing(5)} ${theme.spacing(2)};
          width: 100%;

          .ScreenWrapper__top-bar {
            .MaterialBack {
              font-weight: 500;
              height: unset;

              svg {
                width: 0.8em;
                height: 0.8em;
              }
            }

            margin-bottom: ${theme.spacing(3)};
          }

          .ScreenWrapper__top-image {
            margin: 0 auto ${theme.spacing(7)} auto;
            max-width: 180px;
          }

          .ScreenWrapper__header {
            margin-bottom: ${theme.spacing(3)};

            .ScreenWrapper__header__subtitle {
              margin: ${theme.spacing(3)} 0 0 0;
            }
          }

          .ScreenWrapper__content {
            .widget {
              margin-bottom: ${theme.spacing(3)};
            }
            margin-bottom: ${theme.spacing(10)};
          }

          .ScreenWrapper__actions {
            width: 28rem;
            margin: 0 auto;
            gap: ${theme.spacing(5)};
          }

          .addResourceBox {
            margin-top: ${theme.spacing(9)};

            .addResourceIcon {
              text-align: end;
            }
          }

          .resendWrapper {
            button {
              padding: 3px;
            }
          }

          .button-group-label {
            text-align: start;
          }

          //  TODO - RTL plugin does not flip the padding properly, need to be removed after fixing the plugin
          .MuiInput-input {
            padding-right: 0;
          }

          ${theme.breakpoints.up('sm')} {
            padding: ${theme.spacing(5)} 0;
            max-width: 43.8rem;
          }
        }

        ${theme.breakpoints.up('sm')} {
          border-radius: 2.4rem;
          width: 57.2rem;
          min-height: 78.8rem;
        }

        ${theme.breakpoints.up('md')} {
          width: 77.2rem;
          min-height: 80rem;
        }

        ${theme.breakpoints.up('lg')} {
          width: 102.4rem;
        }
      }
    `;
  }}
`;

const JAPageWrapper = (props) => {
  const { t, i18n } = useTranslation();
  const { actions } = useContext(MainContext);
  const currentStepName = actions.userState.getCurrentStepName();
  const { pageName } = actions.userState.getCurrentPageDetails() ?? {};

  const { footerImageSrc } = actions.settings.getUserConfigProp(`custom.${currentStepName}`) ?? {};

  const defaultBackground = actions.settings.getUserConfigProp(`assets.defaultBackgroundImage`);

  const background = actions.settings.getUserConfigProp(
    `custom.${currentStepName || pageName}.screenWrapper.backgroundImage`,
  );

  return (
    <Wrapper
      {...props}
      backgroundImage={background ?? defaultBackground}
      screenWrapperBackgroundImage={null}
      showBackButtonArrowFallback={true}
      titleVariant="h1"
      footer={
        footerImageSrc && (
          <img
            src={footerImageSrc}
            alt={i18n.exists(`${currentStepName}.footerAlt`) ? t(`${currentStepName}.footerAlt`) : undefined}
          />
        )
      }
    />
  );
};

export default JAPageWrapper;
