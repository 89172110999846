import React from 'react';

import { css, styled } from '@ezbobdev/ui-widgets-ezbob';

const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: ${theme.spacing(3)} 0;

      & > .MuiGrid-container {
        background-color: ${theme.palette.background.default};
        padding: ${theme.spacing(1)} ${theme.spacing(2)};
      }

      .MuiDivider-root {
        margin-top: ${theme.spacing(1)};
      }
    `;
  }}
`;

const ResourceCard = (props) => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default ResourceCard;
