export const stepsNames = {
  applicationSummary: 'applicationSummary',
  termsAndConditions: 'termsAndConditions',
  privacyPolicy: 'privacyPolicy',
  consent: 'consent',
  consentArgenta: 'consentArgenta',
  aboutYourBusiness: 'aboutYourBusiness',
  aboutYourBusinessArgenta: 'aboutYourBusinessArgenta',
  directors: 'directors',
  itsmeFlowArgenta: 'itsmeFlowArgenta',
  applicantDetails: 'applicantDetails',
  aboutYou: 'aboutYou',
  aboutContributor: 'aboutContributor',
  aboutYouArgenta: 'aboutYouArgenta',
  vehicleDetailsArgenta: 'vehicleDetailsArgenta',
  aboutYouJointAccount: 'aboutYouJointAccount',
  previousAddress: 'previousAddress',
  sliders: 'sliders',
  requestAmount: 'requestAmount',
  slidersArgenta: 'slidersArgenta',
  financialDocuments: 'financialDocuments',
  beneficialOwners: 'beneficialOwners',
  beneficialOwnerStatement: 'beneficialOwnerStatement',
  beneficialOwnerStatementReject: 'beneficialOwnerStatementReject',
  referredPage: 'referredPage',
  thankYou: 'thankYou',
  thankYouArgenta: 'thankYouArgenta',
  congratulations: 'congratulations',
  rejectedPage: 'rejectedPage',
  caseExpiredMorning: 'caseExpiredMorning',
  cancel: 'cancel',
  selfDeclaredFinancialDetails: 'selfDeclaredFinancialDetails',
  idvConsent: 'idvConsent',
  financialInformation: 'financialInformation',
  error: 'error',
  idv: 'idv',
  idvSuccess: 'idvSuccess',
  idvError: 'idvError',
  eligibilityRejection: 'eligibilityRejection',
  documentSign: 'documentSign',
  bankAccountSelection: 'bankAccountSelection',
  rlsEligibility: 'rlsEligibility',
  inviteContributors: 'inviteContributors',
  vatLinking: 'vatLinking',
  ocrData: 'ocrData',
  loanAgreement: 'loanAgreement',
  accountConsent: 'accountConsent',
  partnerDetails: 'partnerDetails',
  accountPurpose: 'accountPurpose',
  accountCompliance: 'accountCompliance',
  activitiesAndProducts: 'activitiesAndProducts',
  accountApproval: 'accountApproval',
  approvePartnerDetails: 'approvePartnerDetails',
  creditCardType: 'creditCardType',
  applicationSummaryJointAccount: 'applicationSummaryJointAccount',
  approveActivities: 'approveActivities',
  thankYouJointAccount: 'thankYouJointAccount',
  voiceConsent: 'voiceConsent',
  bankLookup: 'bankLookup',
  aboutYouGreenlend: 'aboutYouGreenlend',
  scrollableConsent: 'scrollableConsent',
  genderAndDOB: 'genderAndDOB',
  voiceRecord: 'voiceRecord',
  otpSend: 'otpSend',
  otpVerification: 'otpVerification',
  welcomeWithConsent: 'welcomeWithConsent',
  aboutTheProcess: 'aboutTheProcess',
  kycQuestions: 'kycQuestions',
  uploadDocuments: 'uploadDocuments',
  ocrFeedback: 'ocrFeedback',
  thankYouSuccess: 'thankYouSuccess',
  welcomeMorning: 'welcomeMorning',
  howItWorksMorning: 'howItWorksMorning',
  firstStageCompletion: 'firstStageCompletion',
  occupationAndProfession: 'occupationAndProfession',
  questionnaire: 'questionnaire',
  referredPageDiscount: 'referredPageDiscount',
  addSignature: 'addSignature',
  rejectedMorning: 'rejectedMorning',
  referredMorning: 'referredMorning',
  extraIdentificationQuestion: 'extraIdentificationQuestion',
  identificationDetailsMorning: 'identificationDetailsMorning',
  firstNameLastNameMorning: 'firstNameLastNameMorning',
  otpPageMorning: 'otpPageMorning',
  otpLoanPageMorning: 'otpLoanPageMorning',
  avgMonthlyIncomeMorning: 'avgMonthlyIncomeMorning',
  secondStageCompletion: 'secondStageCompletion',
  contactDetails: 'contactDetails',
  preIdvStateMorning: 'preIdvStateMorning',
  preBeneficialStateMorning: 'preBeneficialStateMorning',
  creditLinePurposeMorning: 'creditLinePurposeMorning',
  creditLineRepaymentSourceMorning: 'creditLineRepaymentSourceMorning',
  applicationSummaryMorning: 'applicationSummaryMorning',
  completionPageMorning: 'completionPageMorning',
  initialOfferMorning: 'initialOfferMorning',
  nameJA: 'nameJA',
  activeCustomerPageJA: 'activeCustomerPageJA',
  idvOpeningPageJA: 'idvOpeningPageJA',
  au10tixJA: 'au10tixJA',
  authenticateIDJA: 'authenticateIDJA',
  confirmDetailsJA: 'confirmDetailsJA',
  addressPageJA: 'addressPageJA',
  userDetailsInEnglishCountryJA: 'userDetailsInEnglishCountryJA',
  accountPurposeJA: 'accountPurposeJA',
  statusOpeningPageJA: 'statusOpeningPageJA',
  statusCompanyJA: 'statusCompanyJA',
  detailsOfPartnerBJA: 'detailsOfPartnerBJA',
  statusCompanyPartnerBJA: 'statusCompanyPartnerBJA',
  combinedSalaryJA: 'combinedSalaryJA',
  kycOpeningPageJA: 'kycOpeningPageJA',
  incomeResourcesJA: 'incomeResourcesJA',
  expensesJA: 'expensesJA',
  initialDepositJA: 'initialDepositJA',
  otherActivitiesJA: 'otherActivitiesJA',
  productOpeningPageJA: 'productOpeningPageJA',
  productOpeningPageSecondAccountJA: 'productOpeningPageSecondAccountJA',
  accountTypeJA: 'accountTypeJA',
  uploadDocumentJA: 'uploadDocumentJA',
  chooseCommissionJA: 'chooseCommissionJA',
  branchJA: 'branchJA',
  requestAmountMorning: 'requestAmountMorning',
  insufficientCreditLimitMorning: 'insufficientCreditLimitMorning',
  loanSetupSuccessMorning: 'loanSetupSuccessMorning',
  servicesJA: 'servicesJA',
  singleApprovalJA: 'singleApprovalJA',
  deceasedPartnerJA: 'deceasedPartnerJA',
  selectCreditCardJA: 'selectCreditCardJA',
  noCardJA: 'noCardJA',
  declarationPageJA: 'declarationPageJA',
  declarationIsAmericanResidentPageJA: 'declarationIsAmericanResidentPageJA',
  declarationIsPublicFigurePageJA: 'declarationIsPublicFigurePageJA',
  declarationBeneficiaryStatementPageJA: 'declarationBeneficiaryStatementPageJA',
  beneficialOwnerConsentJA: 'beneficialOwnerConsentJA',
  recordingIntroJA: 'recordingIntroJA',
  recordingJA: 'recordingJA',
  signatureJA: 'signatureJA',
  closingJA: 'closingJA',
  bNameJA: 'bNameJA',
  bIdvOpeningPageJA: 'bIdvOpeningPageJA',
  bAu10tixJA: 'bAu10tixJA',
  bAuthenticateIDJA: 'bAuthenticateIDJA',
  bConfirmDetailsJA: 'bConfirmDetailsJA',
  bDetailsInEnglishCountryJA: 'bDetailsInEnglishCountryJA',
  bApprovalOfInputsJA: 'bApprovalOfInputsJA',
  bSelectCardJA: 'bSelectCardJA',
  bNoCardJA: 'bNoCardJA',
  bDeclarationPageJA: 'bDeclarationPageJA',
  bBeneficialOwnerConsentJA: 'bBeneficialOwnerConsentJA',
  bRecordingIntroJA: 'bRecordingIntroJA',
  bRecordingJA: 'bRecordingJA',
  bSignatureJA: 'bSignatureJA',
  bClosingJA: 'bClosingJA',
  exitPageJA: 'exitPageJA',
  offersMorning: 'offersMorning',
  lateLoanExistsMorning: 'lateLoanExistsMorning',
  editAddressJA: 'editAddressJA',
  verifyIdDetailsRetry: 'verifyIdDetailsRetry',
  confirmDetailsNoBackJA: 'confirmDetailsNoBackJA',
  bConfirmDetailsNoBackJA: 'bConfirmDetailsNoBackJA',
  declarationOpeningPageJA: 'declarationOpeningPageJA',
  branchTypeJA: 'branchTypeJA',
  branchNoBackJA: 'branchNoBackJA',
  servicesNoBackJA: 'servicesNoBackJA',
  benefitsJA: 'benefitsJA',
  bAddressPageJA: 'bAddressPageJA',
  bUploadDocumentJA: 'bUploadDocumentJA',
  bEditAddressJA: 'bEditAddressJA',
  bUploadDocumentPageJA: 'bUploadDocumentPageJA',
  bDeclarationOpeningPageJA: 'bDeclarationOpeningPageJA',
  bDeclarationIsAmericanResidentPageJA: 'bDeclarationIsAmericanResidentPageJA',
  bDeclarationIsPublicFigurePageJA: 'bDeclarationIsPublicFigurePageJA',
  bDeclarationBeneficiaryStatementPageJA: 'bDeclarationBeneficiaryStatementPageJA',
  bApprovalOpeningPageJA: 'bApprovalOpeningPageJA',
  aboutYourBusinessBA: 'aboutYourBusinessBA',
  financialsAndKYB: 'financialsAndKYB',
  errorPageJA: 'errorPageJA',
  mandate: 'mandate',
};

export const businessType = {
  soleTrader: 'SOLE_TRADER',
  limitedCompany: 'LIMITED_COMPANY',
  partnership: 'PARTNERSHIP',
};

export const helpType = {
  page: 'page',
  modal: 'modal',
  popover: 'popover',
};

export const wizardDirection = {
  forward: 'FORWARD',
  forwardSecondary: 'FORWARD_SECONDARY',
  backward: 'BACKWARD',
};

export const pageActionType = {
  redirect: 'redirect',
  newTab: 'newTab',
  event: 'event',
  progress: 'progress',
};

export const rumActionType = {
  progress: 'progress',
};

export const systemEvents = {
  DECISION_REJECTED: 'DECISION_REJECTED',
  DECISION_APPROVED: 'DECISION_APPROVED',
  DECISION_REFERRED: 'DECISION_REFERRED',
  CLIENT_TERMINATED: 'CLIENT_TERMINATED',
  CLIENT_CASE_EXPIRED: 'CLIENT_CASE_EXPIRED',
  SESSION_TERMINATED: 'SESSION_TERMINATED',
  LOAN_CREATED: 'LOAN_CREATED',
  ERROR: 'ERROR',
  FLOW_LEAD: 'FLOW_LEAD',
  FLOW_FINISHED: 'FLOW_FINISHED',
  KYC_OPENING_PAGE_LOAD: 'KYC_OPENING_PAGE_LOAD',
  PRODUCT_OPENING_PAGE_LOAD: 'PRODUCT_OPENING_PAGE_LOAD',
};

export const recordingDocType = {
  SPEECH_TO_TEXT_SENTENCE_COMPARISON: 'SPEECH_TO_TEXT_SENTENCE_COMPARISON',
  BENEFICIAL_OWNER_CONSENT: 'BENEFICIAL_OWNER_CONSENT',
};

export const docType = {
  BANK: 'BANK',
};

export const occupationStatus = {
  EMPLOYEE: 'Employee',
};

export const occupationSubStatus = {
  OTHER: 'Other',
};

export const incomeTypes = {
  ABROAD: 'IncomeTypeAbroad',
};

export const expenseTypes = {
  ABROAD: 'ExpenseAbroad',
};

export const FlowTypes = {
  CONTRIBUTOR: 'contributor',
};

export const feeTracks = {
  NO_PACKAGE: 'NoPackage',
};
